:root {
  --black: #282828;
  --point: #ffb307;
  --point-light: lighten(var(--point), 5%);

  --ratio: 1.618;
  --pretty-card_width: 250px;
  --pretty-card_height: var(--pretty-card_width) * var(--ratio);
  --pretty-card_padding: 20px;
  --pretty-card-bgcolor: white;
  --pretty-card_margin: 5px;
  --pretty-card_round: 10px;
  --pretty-card-shadow: -20px -20px 0px 0px var(--point-light);

  --pretty-card-padding-basis: 15px;
  --pretty-card_head_padding: 0px 0px var(--pretty-card-padding-basis) 0px;

  --pretty-card-width: 280px;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

/* Pretty Card Animations and Styling */
.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pretty-container {
  width: 80%;
  /* min-height: 100vh; */
  /* margin-top: '2em'; */
  /* margin-top: calc(0.75rem + 25px); */
  margin-top: calc(1rem + 25px);
  max-width: 320px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  margin-left: auto;
  margin-right: auto;
}

.container {
  width: 80%;  
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  margin-left: auto;
  margin-right: auto;
}

.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  flex-direction: column;
}

.pretty-card {
  background-color: white;
  min-height: 350.5px;
  min-width: 250px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 0px;

  -webkit-box-shadow: -20px -20px 0px 0px #ffb307;
  box-shadow: -20px -20px 0px 0px #ffb307;
  border-radius: 10px;

  -webkit-animation-name: shadow-show;
  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.5s;

  /* Safari 4.0 - 8.0 */
  animation-name: shadow-show;
  animation-duration: 1.5s;
  -webkit-transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1);
  -o-transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1);
  transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1);
  /* custom */
}

.pretty-card h1,
.pretty-card h2,
.pretty-card h3,
.pretty-card h4,
.pretty-card h5 {
  margin: 0px;
  padding: var(--pretty-card_head_padding);
  font-family: "Noto Sans KR", sans-serif;
  font-size: 30px;
  color: var(--black);
}

.pretty-card hr {
  display: block;
  border: none;
  height: 3px;
  background-color: var(--point);
  margin: 0px;

  -webkit-animation-name: line-show; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 0.3s; /* Safari 4.0 - 8.0 */
  animation-name: line-show;
  animation-duration: 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1);
  -o-transition-timing-function: cubizc-bezier(0.795, 0, 0.165, 1);
  transition-timing-function: cubic-bezier(0.795, 0, 0.165, 1); /* custom */
}

.pretty-card p {
  margin: var(--pretty-card-padding-basis) 0px 0px 0px;
  font-family: "Noto Sans KR", sans-serif;
  font-weight: 100;
  letter-spacing: -0.25px;
  line-height: 1.25;
  font-size: 16px;
  word-break: break-all;
  word-wrap: pre-wrap;
  color: var(--black);

  -webkit-animation-name: p-show; /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 1.5s; /* Safari 4.0 - 8.0 */
  animation-name: p-show;
  animation-duration: 1.5s;
}

.pretty-card .button {
  border: none;
  display: flex;
  background-color: var(--point);
  border-radius: 6px;
  margin: 10px auto;
  padding: 10px 30px;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  text-transform: uppercase;
}
.yellow-button {
  border: none;
  display: flex;
  justify-content: center;
  background-color: var(--point);
  border-radius: 6px;
  margin: 10px auto;
  padding: 10px 30px;
  color: white;
  font-family: "Noto Sans KR", sans-serif;
  text-transform: uppercase;

}

.shoot {
  animation: sampleanimation 0.6s forwards;
}

.hide {
  opacity: 0;
}

@keyframes sampleanimation {
  from {
    -webkit-box-shadow: -20px -20px 0px 0px #ffb307;
    box-shadow: -20px -20px 0px 0px #ffb307;
    opacity: 1;
  }
  to {
    -webkit-box-shadow: -100vw -20px 0px 0px #ffb307;
    box-shadow: -100vw -20px 0px 0px #ffb307;
    opacity: 0;
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes line-show {
  from {
    margin: 0px 100px;
  }
  to {
    margin: 0px;
  }
}
/* Standard syntax */
@keyframes line-show {
  from {
    margin: 0px 100px;
  }
  to {
    margin: 0px;
  }
}
/* Safari 4.0 - 8.0 */
@-webkit-keyframes p-show {
  from {
    color: white;
  }
  to {
    color: #282828;
  }
}
/* Standard syntax */
@keyframes p-show {
  from {
    color: white;
  }
  to {
    color: #282828;
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes shadow-show {
  from {
    -webkit-box-shadow: 0px 0px 0px 0px #e0e0e0;
    box-shadow: 0px 0px 0px 0px #e0e0e0;
  }
  to {
    -webkit-box-shadow: -20px -20px 0px 0px #ffb307;
    box-shadow: -20px -20px 0px 0px #ffb307;
  }
}
/* Standard syntax */
@keyframes shadow-show {
  from {
    -webkit-box-shadow: 0px 0px 0px 0px #e0e0e0;
    box-shadow: 0px 0px 0px 0px #e0e0e0;
  }
  to {
    -webkit-box-shadow: -20px -20px 0px 0px #ffb307;
    box-shadow: -20px -20px 0px 0px #ffb307;
  }
}
