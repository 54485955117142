.container {
  display: flex;
  justify-content: center;
}

.center {
  display: flex;
  justify-content: center;
}

.background-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: absolute;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  left: -8px;
  top: -8px;

  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

.card {
  margin-top: 50%;
  border: solid 2px #989898;
  padding: 1.5rem;
  z-index: 2;  
}

.btn {
  background-color: #ffb307;
  color: black; 
  border-radius: 4px;
  font-size: 0.75rem;
  font-family: "OpenSans", sans-serif;
  padding: 1.25rem;
  font-weight: bold; 
  letter-spacing: 1.8px; 
  margin-top: 2rem; 
}