:root {
  --icon_size: 25px;
}

.container {
  display: flex;
  z-index: 1;
}

.button {
  padding-left: 0.25em;
  padding-right: 0.25em;
  margin: 0px;
  height: fit-content;
  border: 0px;
  background-color: transparent;
  display: flex;
  font-weight: 500;
  font-size: 1rem;
  font-family: "Noto Sans KR", sans-serif;

  opacity: 0.25
}

.splitter {
  width: 2px;
  background-color: rgba(0, 0, 0, 0.2);
}

.button.active {  
  opacity: 1;
}


.img_icon {
  width: var(--icon_size);
  height: var(--icon_size);
}