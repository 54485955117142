.master-wrapper {
  height: 100%;
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
}

.check-list {
  margin: 0;
  padding-left: 0;
}

.check-list .text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 1em;
  padding-right: 1em;
}

.element {
  display: flex;
  align-items: center;
  position: relative;
  list-style-type: none;
  padding-left: 2.5rem;
  margin-bottom: 1rem;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  cursor: default;
  border-radius: 10px;
  color: white;
  padding: 1rem;

  -webkit-font-smoothing: antialiased;
  font-family: "Helvetica Neue", sans-serif;

  /* font-size: 62.5%; */
}

.element.active {
  -webkit-animation: hover 0.5s forwards;
  -moz-animation: hover 0.5s forwards;
  -o-animation: hover 0.5s forwards;
  animation: hover 0.5s forwards;
}

.icon {
  margin-right: 0.5rem;
  height: 1.75rem !important;
  width: 1.75rem !important;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes hover {
  from {    
    -webkit-box-shadow: 0px 0px 0px 0px #e0e0e0;
    box-shadow: 0px 0px 0px 0px #e0e0e0;
  }
  to {
    -webkit-box-shadow: -20px 0px 0px 0px #ffb307;
    box-shadow: -20px 0px 0px 0px #ffb307;
  }
}
@-moz-keyframes hover {
  from {
    -webkit-box-shadow: 0px 0px 0px 0px #e0e0e0;
    box-shadow: 0px 0px 0px 0px #e0e0e0;
  }
  to {
    -webkit-box-shadow: -20px 0px 0px 0px #ffb307;
    box-shadow: -20px 0px 0px 0px #ffb307;
  }
}
@-o-keyframes hover {
  from {
    -webkit-box-shadow: 0px 0px 0px 0px #e0e0e0;
    box-shadow: 0px 0px 0px 0px #e0e0e0;
  }
  to {
    -webkit-box-shadow: -20px 0px 0px 0px #ffb307;
    box-shadow: -20px 0px 0px 0px #ffb307;
  }
}
/* Standard syntax */
@keyframes hover {
  from {
    -webkit-box-shadow: 0px 0px 0px 0px #e0e0e0;
    box-shadow: 0px 0px 0px 0px #e0e0e0;
  }
  to {
    -webkit-box-shadow: -20px 0px 0px 0px #ffb307;
    box-shadow: -20px 0px 0px 0px #ffb307;
  }
}

.fast-transition {
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}

.fast-transition::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  /* border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px; */
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 500ms;
}

.fast-transition:hover::before {
  opacity: 1;
}

.fast-transition.active::before {
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.5);
  opacity: 1;
  transition: opacity 500ms;
  transition: border-radius 1000ms;
}

html,
body,
section {
  height: 100%;
}