.container {
  margin-bottom: 25px; /* to avoid the card 'shadow' */
  width: calc(100% + 1.2em);
  margin-left: -1.2em;
  position: relative;
}
.relative {
  position: relative;
}
.steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  /* width: calc(100% + 0.25rem); */
  width: 100%;
}
.step {
  width: 0.8rem;
  height: 0.8rem;
  background: white;
  border: 2px solid white;
  border-radius: 50%;
  transition: background 1s;
}
.step.selected {
  border: 2px solid #ffb307;
}
.step.completed {
  border: 2px solid #ffb307;
  background: #ffb307;
}
.progress {
  position: absolute;
  /* width: 100%; */
  width: 99%;
  height: calc(50% - 1px);
  border-bottom: 2px solid #acaca6;
  z-index: -1;
}
.percent {
  position: absolute;
  width: 0;
  height: 100%;
  border-bottom: 2px solid #ffb307;
  z-index: 1;
  transition: width 1.5s;
}
.progress_number {
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  color: white;
  padding-top: 5px;
}
