.cookie-consent {
    position: fixed;
    right: 0px;
    left: 0px;
    bottom: 0px;

    background-color: #fff;
    padding: 0.5rem;

    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.consent-message {
    margin-right: 1rem;
}