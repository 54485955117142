.popover-paper {
  margin-bottom: -1rem;
  margin-left: -1rem;
}

.root {
  background-color: rgba(128,128,128, 0.5);
}

.pulse {
  box-shadow: 0 0 0 rgba(255, 179, 7, 0.4);
  animation: pulse 2s infinite;
}

.pulse_normal {
  box-shadow: 0 0 0 rgba(230, 230, 250, 0.5);
  animation: pulse_normal 2s 5s infinite;
}

@-webkit-keyframes pulse_normal {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(230, 230, 250, 0.5);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(230, 230, 250, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(230, 230, 250, 0);
  }
}
@keyframes pulse_normal {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(230, 230, 250, 0.5);
    box-shadow: 0 0 0 0 rgba(230, 230, 250, 0.5);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(230, 230, 250, 0);
    box-shadow: 0 0 0 10px rgba(230, 230, 250, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(230, 230, 250, 0);
    box-shadow: 0 0 0 0 rgba(230, 230, 250, 0);
  }
}


.pulse_medium {
  box-shadow: 0 0 0 rgba(255, 179, 7, 0.5);
  animation: pulse_medium 2s 3.5s infinite;
}

@-webkit-keyframes pulse_medium {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 179, 7, 0.5);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 179, 7, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 179, 7, 0);
  }
}
@keyframes pulse_medium {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 179, 7, 0.5);
    box-shadow: 0 0 0 0 rgba(255, 179, 7, 0.5);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(255, 179, 7, 0);
    box-shadow: 0 0 0 10px rgba(255, 179, 7, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255, 179, 7, 0);
    box-shadow: 0 0 0 0 rgba(255, 179, 7, 0);
  }
}


.pulse_high {
  box-shadow: 0 0 0 rgba(165, 42, 32, 0.5);
  animation: pulse_high 2s 5s infinite;
}

@-webkit-keyframes pulse_high {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(165, 42, 32, 1);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(165, 42, 32, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(165, 42, 32, 0);
  }
}
@keyframes pulse_high {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(165, 42, 32, 1);
    box-shadow: 0 0 0 0 rgba(165, 42, 32, 1);
  }
  70% {
    -moz-box-shadow: 0 0 0 15px rgba(165, 42, 32, 0);
    box-shadow: 0 0 0 15px rgba(165, 42, 32, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(165, 42, 32, 0);
    box-shadow: 0 0 0 0 rgba(165, 42, 32, 0);
  }
}
